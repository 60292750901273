import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as IconTapAPI from "@/api/analysis/icon-tap";
import { isSuccess } from "@/api/response";
import { IconTapListGetRequest } from "@/api/analysis/icon-tap/request";
import { IconTapGetResponse } from "@/api/analysis/icon-tap/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/icon-tap/get";

/**
 *  アイコンタップAPI（/analysis-icon-tap）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: IconTapListGetRequest = {} as IconTapListGetRequest;
  getResponse: IconTapGetResponse = {} as IconTapGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getData() {
    if (this.getResponse.results) {
      return this.getResponse.results.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getTopType() {
    return this.getResponse.results.topType;
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: IconTapListGetRequest) {
    const getResponse = await IconTapAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as IconTapGetResponse
    };
  }
}

export default getModule(Get);
