import service from "@/api/service";
import { IconTapListGetRequest } from "@/api/analysis/icon-tap-list/request";
import { IconTapListGetResponse } from "@/api/analysis/icon-tap-list/response";

/**
 * アイコンタップ数表取得APIをコールします。
 *
 * @param getRequest アイコンタップ数表取得APIのリクエストボディ
 * @return IconTapListGetResponse
 */
export async function get(getRequest: IconTapListGetRequest) {
  const response = await service.post("/analysis-icon-tap-list", getRequest);
  return response.data as IconTapListGetResponse;
}
